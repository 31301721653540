import React from "react";
import { graphql } from "gatsby";

import RegisterPage from "../../plugins/gatsby-parallelpublicworks/src/layouts/RegisterPage";

export default function RegisterPageWrapper({ data }) {
  return (
    <RegisterPage
      data={data}
      info="User accounts are only available to staff members and volunteers of Community Action Agencies in Washington State."
    />
  );
}
export const query = graphql`
  {
    field_agency: allEntityRef(
      filter: { type: { eq: "node--agency" } }
      sort: { fields: title }
    ) {
      edges {
        node {
          id
          nid
          title
          type
        }
      }
    }
    field_work_group: allEntityRef(
      filter: { type: { eq: "node--work_group" } }
      sort: { fields: title }
    ) {
      edges {
        node {
          id
          nid
          title
          type
        }
      }
    }
    field_expertise: allTermRef(
      filter: { type: { eq: "taxonomy_term--expertise" } }
      sort: { fields: title }
    ) {
      edges {
        node {
          id
          tid
          title
          type
        }
      }
    }
  }
`;
