import React from "react";
import { Link } from "gatsby";
import { Register } from "../components/";
import { SiteContext } from "../layouts/Main";

function RegisterPage({ data, info, children }) {
  const [noJS, setNoJS] = React.useState(true);
  const [requestSent, setRequestSent] = React.useState(null);
  const { drupalUrl } = React.useContext(SiteContext);

  React.useEffect(() => {
    setNoJS(false);
  }, []);

  const onSubmit = async (form_data, setSubmitting, setError) => {
    setSubmitting(true);
    setError(null);
    try {
      const resp = await fetch(drupalUrl + "/user/register?_format=json", {
        method: "POST",
        mode: "cors",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(form_data),
      });
      if (resp.status === 200) {
        setRequestSent(
          <>
            <strong>Thank you for your submission!</strong> We have received your request
            and are verifying your account now. You will receive an email on how to finish
            setting up your account. <Link to="/">Return to homepage</Link>
          </>
        );
      } else {
        const respJson = await resp.json();
        if (respJson.message) {
          let splitMessage = respJson.message.split(/\n/g);
          let message = splitMessage.shift();
          while (message.search("Unprocessable Entity") > -1) {
            message = splitMessage.shift();
          }
          setError(message);
        }
      }
      setSubmitting(false);
    } catch (err) {
      setError(err.message);
    }
  };
  if (noJS) return <></>;
  return (
    <>
      {children}
      <Register
        requestSent={requestSent}
        title="Request Account"
        onSubmit={onSubmit}
        graphqlData={data}
        info={info}
      />
    </>
  );
}

export default RegisterPage;
